// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-nlp-champs-index-js": () => import("./../../../src/pages/about-nlp-champs/index.js" /* webpackChunkName: "component---src-pages-about-nlp-champs-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-getting-started-index-js": () => import("./../../../src/pages/getting-started/index.js" /* webpackChunkName: "component---src-pages-getting-started-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-meet-the-champs-or-become-a-champ-index-js": () => import("./../../../src/pages/meet-the-champs-or-become-a-champ/index.js" /* webpackChunkName: "component---src-pages-meet-the-champs-or-become-a-champ-index-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-snek-index-js": () => import("./../../../src/pages/snek/index.js" /* webpackChunkName: "component---src-pages-snek-index-js" */),
  "component---src-pages-the-nlp-times-newsletter-index-js": () => import("./../../../src/pages/the-nlp-times-newsletter/index.js" /* webpackChunkName: "component---src-pages-the-nlp-times-newsletter-index-js" */),
  "component---src-pages-what-is-nlp-index-js": () => import("./../../../src/pages/what-is-nlp/index.js" /* webpackChunkName: "component---src-pages-what-is-nlp-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

